/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  
  function watchNavScroll() {
    var banner_height = 0;
    
    if($('#banner').length) {
      banner_height = $('#banner').height();
    }
    
    if($(window).scrollTop() > banner_height) {
      $('body').addClass('fixed-nav');
    }
    else {
      $('body').removeClass('fixed-nav');
    }
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        watchNavScroll();
        
        $(window).on('load scroll resize', function() {
          watchNavScroll();
        });
        
        $('.gallery').magnificPopup({
          delegate: 'a',
          type: 'image',
          tLoading: 'Loading image #%curr%...',
          mainClass: 'mfp-img-mobile',
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
          },
          image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
          }
        });
        
        $('#menu-toggle').on('click', function(e) {
          e.preventDefault();
          
          $('.nav-primary').slideToggle('normal');
        });
        
        if($('.give-hope-open').length) {
          $('.give-hope-open').magnificPopup({
            type: 'inline'
          });
          
          if(!$.cookie('give_hope_popup')) {
            setTimeout(function() {
              $('.give-hope-open').magnificPopup('open');
              $.cookie('give_hope_popup', 1, { expires: 7 });
            }, 3000);
          }
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $(window).on('load', function() {
          $('#slideshow img[data-src]').each(function() {
            $(this).attr('src', $(this).attr('data-src'));
          });
          
          $('#slideshow').slick({
            arrows: false,
            fade: true,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 700
          });
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
